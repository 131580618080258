
import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function SelectRows({setRows, rows}) {

  const handleChange = (event) => {
    setRows(event.target.value);
  };

  return (
    <div className='min-w-[120px] bg-white rounded-md sahdow-md'>
      <FormControl fullWidth>
        <Select
          // labelId={''}
          id={'rows select'}
          value={rows}
          sx={{height: '40px', border: 'none', "& fieldset": { border: 'none' }}}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 200,
                height: 'auto',
                border: 'none' // Set border to none
              },
            },
          }}
          onChange={handleChange}
        >
          <MenuItem value={10}> 10 Rows</MenuItem>
          <MenuItem value={25}> 25 Rows</MenuItem>
          <MenuItem value={50}> 50 Rows</MenuItem>
          <MenuItem value={100}> 100 Rows</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}
