import { UploadFile } from "@mui/icons-material"

const UploadImage = ({rowid, filerowId, handleFileAdd, line_number}) => {
    return <div className="flex items-center justify-center">
        <label
            htmlFor={`${filerowId}-file-upload`}
            className="flex flex-col items-center justify-center w-full border-1 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50  hover:bg-gray-100 "
        >
            <div className="flex  flex-col py-2 justify-start items-center space-x-2 ">
                <UploadFile className="text-gray-500" />
                <p className=" font-semibold text-sm text-gray-500 ">
                    Click to upload
                </p>
                <p className="text-xs text-gray-500 ">
                    Image, CAD, File
                </p>
            </div>
            <input
            onChange={(e) => handleFileAdd(e, rowid, 'uploaded',  line_number)}
            id={`${filerowId}-file-upload`} type="file" className="hidden" />
        </label>
    </div>

}

export default UploadImage