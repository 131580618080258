
import * as React from 'react';
import Badge from '@mui/material/Badge';
import { ShoppingCart } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import { useSelector } from 'react-redux';

export default function TotalCart() {
  const carts = useSelector(state => state?.cartReducer?.carts)

  return (
    <Tooltip placement='bottom' arrow title='Your Cart'>
      <Badge badgeContent={carts?.ids?.length || 0} color='error'>
        <ShoppingCart />
      </Badge>
    </Tooltip>
  );
}

