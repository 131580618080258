



import { ACTION_HANLDE_CART_UPDATE } from "src/Services/Constant/types"

// **  Initial State
const initialState = {
    carts: JSON.parse(localStorage.getItem('carts'))  || {
        data:[],
        ids:[]
    }
}

const cartReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_HANLDE_CART_UPDATE:
            localStorage.setItem('carts', JSON.stringify(action?.data))
            return {
                ...state,
                carts: action.data || []
            }
        default:
            return state
    }
}

export default cartReducer