import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function DaysSelect({ hooks: { day, handleDays }, id }) {

  const handleChange = (event) => {
    handleDays(event.target.value);
  };

  return (
    <div className='min-w-[120px] bg-white rounded-md sahdow-md'>
      <FormControl fullWidth>
        <Select
          // labelId={{ id }}
          id={id}
          value={day}
          label="Filter By Days"
          sx={{height: '40px', border: 'none', "& fieldset": { border: 'none' }}}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 200,
                height: 'auto',
                border: 'none' // Set border to none
              },
            },
          }}
          onChange={handleChange}
        >
          <MenuItem value={30}> 30 Days</MenuItem>
          <MenuItem value={90}> 90 Days</MenuItem>
          <MenuItem value={120}> 120 Days</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}
