
import React, { useEffect, useMemo, useState } from 'react';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ReactPaginationStyle from 'src/Components/ReactPagination';
import SelectRows from 'src/Components/SelectRows';
import CardLoader from 'src/Components/CardLoader';
import { ACTION_HANLDE_CART_UPDATE } from 'src/Services/Constant/types';
import { useDispatch, useSelector } from 'react-redux';
import { AlertInfo } from 'src/Services';
import moment from 'moment'

export default function ArchiveTable({ hooks }) {
    let {
        data, totalRecords,
        page, handleRows, rows, loader,
        handlePagination
    } = hooks;
    const dispatch = useDispatch();
    const carts = useSelector(state => state?.cartReducer?.carts)
    const [rowSelection, setRowSelection] = useState({});

    const columns = useMemo(
        () => [
            {
                accessorKey: 'INVOICEDATE',
                header: 'Invoice Date',
                filterVariant: 'range',
                Cell: ({ cell }) => {
                    return <span>{moment(new Date(cell?.getValue())).format('MM-DD-YYYY')}</span>;
                  },
            },
            {
                accessorKey: 'INVOICEID',
                header: 'Invoice ID',
                filterVariant: 'text',
            },
            {
                accessorKey: 'SALESID',
                header: 'Order',
                filterVariant: 'text',
            },
            {
                accessorKey: 'ITEMID',
                header: 'Style',
                filterVariant: 'text',
            },
            {
                accessorKey: 'TAGGEDCARATWT',
                header: 'Version',
                filterVariant: 'text',
            },
            {
                accessorKey: 'CENTERSTONESHAPE',
                header: 'Center Shape',
                filterVariant: 'text',
            },
            {
                accessorKey: 'CENTERSTONEWEIGHT',
                header: 'Center Size',
                filterVariant: 'text',
            },
            {
                accessorKey: 'METALDESC',
                header: 'Metal',
                filterVariant: 'text',
            },
            {
                accessorKey: 'CENTERSTONEQUALITY',
                header: 'Quality',
                filterVariant: 'text',
            },
            {
                accessorKey: 'SALESPRICE',
                header: 'Sales Price',
                filterVariant: 'text',
            },
            {
                accessorKey: 'QTY',
                header: 'Quantity',
                filterVariant: 'text',
            },
            {
                accessorKey: 'LINEAMOUNT',
                header: 'Line Amount',
                filterVariant: 'text',
            }
        ],
        [],
    );


    const runSelectdRowLogic = ()=>{
        const debounceTimer = setTimeout(() => {
            let selectedAre = {}
            for (let id of carts?.ids) {
                selectedAre[id] = true
            }
            setRowSelection(selectedAre)
        }, 900)

        return () => {
            clearTimeout(debounceTimer)
        }
    }
    
    useEffect(() => {
        runSelectdRowLogic()
    }, [])
    

    const uId = (row)=>{
        return `${row?.INVENTTRANSID}-${row?.INVOICEID}`
    }  

    const table = useMaterialReactTable({
        columns,
        data,
        initialState: { density: 'compact' },
        enableStickyHeader: true,
        enableFullScreenToggle: true,
        renderBottomToolbar: false,
        enableRowSelection: true,
        getRowId: (row) => uId(row),
        state: { rowSelection },
    
        muiTableContainerProps: { sx: { maxHeight: '70vh' }, sm: { Height: '80vh' } },

        renderTopToolbarCustomActions: () => (
            <div className='flex justify-start items-center space-x-4'>
                <ReactPaginationStyle
                    total={totalRecords}
                    currentPage={page}
                    handlePagination={(value) => {
                        handlePagination(value)
                        runSelectdRowLogic()
                        // clear select on page change
                        // setRowSelection({})
                    }}
                    limit={rows}
                />
                <SelectRows rows={rows} setRows={handleRows} />
            </div>
        ),

        onRowSelectionChange: setRowSelection,
    });

    useEffect(() => {
        let checkedItems = Object.keys(rowSelection)
        if(checkedItems?.length){
            let allCartIds = carts?.ids
            let cartlist = carts?.data
    
            for (let rowKey of checkedItems) {
                if(!allCartIds?.includes(rowKey)){
                    let newItem = data?.find(item => uId(item) === rowKey)
                    if(newItem){
                        allCartIds.push(rowKey)
                        cartlist.push({...newItem, cartfrom:'ARCHIVED'})
                    }
                   
                }else{
                    AlertInfo('Item is already in cart')
                }
              
            }
            dispatch({
                type: ACTION_HANLDE_CART_UPDATE, data: {
                    data: cartlist,
                    ids: allCartIds
                }
            })
        }
    }, [rowSelection])

    return (
        <>
            {
                loader ? <CardLoader /> : <LocalizationProvider>
                    <MaterialReactTable
                        getRowId={(row) => console.log(row)}
                        table={table} />
                </LocalizationProvider>
            }
        </>

    );
}
