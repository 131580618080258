import { ToastContainer } from "react-toastify"
import InvoiceLayout from "src/Layouts/invoice/list"

const InvoicePage = () => {

    return (
        <div className='w-full'>
            <InvoiceLayout />
            <ToastContainer
            position="bottom-center"
            newestOnTop limit={1} />
        </div>
    )
}

export default InvoicePage