// import { AlertError, AlertSuccess } from "src/Services"
import moment from "moment"
import { postMethodApiCall } from "src/Services/API"
import { ACTION_GET_INVOICES } from "src/Services/Constant/types"

const baseUrl = process.env.REACT_APP_BASE_URL

const getHeadersWithoutAuth = () => {
    return {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('token')}`
    }
}

export const GET_INVOICES = data => {
    return async dispatch => {
        try {
            let payload = {
                ...data,
                start_date: moment(new Date(data.start_date)).format('YYYY-MM-DD'),
                end_date: moment(new Date(data.end_date)).format('YYYY-MM-DD')
            }
            const url = `${baseUrl}/odata/get/portal/invoices`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            if (res?.status === 200 || res?.status === 201) {
                dispatch({type: ACTION_GET_INVOICES, data: { list: res?.data?.data, pagination: res?.data?.pagination } || { list: 0, pagination: {} }})
            } else {
                return { success: false, message: res?.data?.message }
            }
        } catch (error) {
            console.log((error?.response?.data).replace(/\\/g, ""))
        }
    }
}