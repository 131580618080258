import { ToastContainer } from "react-toastify";
import LoginLayout from "src/Layouts/user/login";


const LoginScreen = () => {
    return (
        <>
            <LoginLayout />
            <ToastContainer
                position="bottom-center"
                newestOnTop limit={1} />
        </>
    )
}

export default LoginScreen;