import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TuneIcon from '@mui/icons-material/Tune';

import SearchByColumn from './searchByColumn';
import DaysSelect from './daysSelect';

export default function FilterModal({ hooks }) {
    let { fetchOrders,
        searchStr,
        setSearchStr,
        filterField,
        setFilterFiled,
        apiPayload,
        handleDays,
        handledate,
        day } = hooks

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        fetchOrders()
    };

    return (
        <React.Fragment>
            <IconButton className='bg-white' variant="outlined" onClick={handleClickOpen}>
                <TuneIcon />
            </IconButton>
            <Dialog
                maxWidth={'lg'}
                open={open}
                onClose={()=>setOpen(false)}
            >
                <DialogTitle id="alert-dialog-title">
                    Filters
                </DialogTitle>
                <DialogContent >
                    <SearchByColumn hooks={{
                        fetchOrders,
                        searchStr,
                        setSearchStr,
                        filterField,
                        setFilterFiled,
                        selectWidth: '100px'
                    }} />

                    <div className='w-full my-2'>
                        <h6 className='mb-1 text-gray-600'>Filter By Days</h6>
                        <div className='border rounded-md'>
                            <DaysSelect
                                hooks={{ day, handleDays }}
                                id='select-last-days-filter'
                            />
                        </div>
                    </div>

                    <div className='w-full my-2'>
                        <h6 className='mb-1 text-gray-600'>Start Date</h6>
                        <input type='date' className='h-10 border rounded-md w-full px-2' value={apiPayload?.start_date} name='start_date' onChange={handledate} />
                    </div>

                    <div className='w-full my-2'>
                        <h6 className='mb-1 text-gray-600'>End Date</h6>
                        <input type='date' className='h-10 border rounded-md w-full px-2' value={apiPayload?.end_date} name='end_date' onChange={handledate} />
                    </div>

                </DialogContent>
                <DialogActions>
                    <button className='border-1 border-gray-500 text-gray-500 px-3 py-1 rounded-md' onClick={handleClose}>Clear</button>
                    <Button className='bg-gradient-to-tr  from-gray-700 to-gray-400 text-white py-1 px-2 font-[300] rounded-md' onClick={handleClose} autoFocus>
                        Apply
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
