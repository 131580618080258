import { useSelector } from 'react-redux'

const Notes = () => {
    const getOrderAttachment = useSelector(state => state?.ordersReducer?.getOrderAttachment)
    return (
        <>
            <div>
                <h6 className=" font-[700] text-gray-600 ml-7">Notes:</h6>
                <p> {getOrderAttachment?.NOTES}</p>
            </div>
        </>
    )
}

export default Notes;