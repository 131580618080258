// import { AlertError, AlertSuccess } from "src/Services"
import moment from "moment"
import { postMethodApiCall } from "src/Services/API"
import { ACTION_GET_WEBORDERS } from "src/Services/Constant/types"
import  { ACTION_GET_WEBORDER_DETAILS } from "src/Services/Constant/types"

const baseUrl = process.env.REACT_APP_BASE_URL

const getHeadersWithoutAuth = () => {
    return {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('token')}`
    }
}

export const GET_WEBORDERS = data => {
    return async dispatch => {
        try {
            let payload = {
                ...data,
                start_date: moment(new Date(data.start_date)).format('YYYY-MM-DD'),
                end_date: moment(new Date(data.end_date)).format('YYYY-MM-DD')
            }
            const url = `${baseUrl}/odata/get/weborders/`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            if (res?.status === 200 || res?.status === 201) {
                // dispatch({ type: ACTION_GET_WEBORDERS, data: { list: res?.data?.sales_line_rows, pagination: res?.data?.pagination } || { list: 0, pagination: {} } })
                dispatch({type: ACTION_GET_WEBORDERS, data:{list: res?.data?.rows , pagination:res?.data?.pagination} || {list: 0, pagination: {}}})
            } else {
                return { success: false, message: res?.data?.message }
            }
        } catch (error) {
            console.log((error?.response?.data).replace(/\\/g, ""))
        }
    }
}

export const GET_WEBORDER_DETAILS = data => {
    return async dispatch => {
        try {
            const url = `${baseUrl}/odata/WebOrder/details`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
            if (res?.status === 200 || res?.status === 201) {
                // dispatch({ type: ACTION_GET_WEBORDERS, data: { list: res?.data?.sales_line_rows, pagination: res?.data?.pagination } || { list: 0, pagination: {} } })
                dispatch({type: ACTION_GET_WEBORDER_DETAILS, data:{list: res?.data?.rows} || {list: 0}})
            } else {
                return { success: false, message: res?.data?.message }
            }
        } catch (error) {
            console.log((error?.response?.data).replace(/\\/g, ""))
        }
    }
}