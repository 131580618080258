import React, { useState, useEffect } from 'react';

const ImageWithFallbackAndLoader = ({ src, fallbackSrc, alt, className }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [loadError, setLoadError] = useState(false);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  const handleImageError = () => {
    setIsLoading(false);
    setLoadError(true);
  };

  useEffect(() => {
    setLoadError(false);
  }, [src]);

  return (
    <>
      {isLoading && <div>Loading...</div>}
      {loadError ? (
        <img src={fallbackSrc} alt={alt} />
      ) : (
        <img
          src={src}
          alt={alt}
          className={className}
          onLoad={handleImageLoad}
          onError={handleImageError}
          style={{ display: isLoading || loadError ? 'none' : 'block' }}
        />
      )}
      {isLoading && !loadError && <img src={fallbackSrc} alt={alt} style={{ opacity: 0 }} />}
    </>
  );
};

export default ImageWithFallbackAndLoader;
