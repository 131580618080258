import moment from "moment";
import { useEffect, useState } from "react";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const InputField = ({
    errors,
    name,
    className,
    register,
    setValue,
    value = '',
    maxLength = 10,
    min,
    required = false,
    errorMsg = 'required!',
    type = 'text'
}) => {
    const [selectedDate, setSelectedDate] = useState(value);

    useEffect(() => {
        if(type === 'date'){
            setValue(name, moment(selectedDate).format('YYYY-MM-DD'));
        }
    }, [selectedDate, setValue, name]);

    return (
        <>
            {type === 'date' ? (
                <div id="date-picker-custom-id">
                    <DatePicker
                        selected={selectedDate}
                        onChange={(date) => setSelectedDate(date)}
                        className={className}
                    />
                </div>
            ) : (
                <input
                    maxLength={maxLength}
                    type={type}
                    min={min}
                    {...register(name, {
                        value,
                        required
                    })}
                    className={className}
                />
            )}
            {errors[name] && <p className="text-red-400 text-sm">{errors[name]?.message || errorMsg}</p>}
        </>
    );
};
export default InputField;
