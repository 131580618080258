import { ToastContainer } from "react-toastify"
import DashboardCharts from "src/Layouts/dashboard"


const DashboardPage = () => {

    return <>
        <DashboardCharts />
        <ToastContainer
            position="bottom-center"
            newestOnTop limit={1} />
    </>
}

export default DashboardPage