import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import TuneIcon from '@mui/icons-material/Tune';
import DatePicker from 'react-datepicker';
import SearchByColumn from './searchByColumn';
import DaysSelect from './daysSelect';
import Drawer from 'src/Components/Drawer';
import CloseIcon from '@mui/icons-material/Close';

export default function FilterModal({ hooks }) {
    let { fetchOrders,
        searchStr,
        setSearchStr,
        filterField,
        setFilterFiled,
        apiPayload,
        handleDays,
        handledate,
        day } = hooks

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        fetchOrders()
    };

    return (
        <React.Fragment>
            <IconButton className='bg-white' variant="outlined" onClick={handleClickOpen}>
                <TuneIcon />
            </IconButton>
            <Drawer
                isOpen={open}
                setIsOpen={setOpen}
                title={'Filters'}
            >
                <div className='relative p-4 marker:flex flex-col items-start'>
                    <div className=' mb-3 text-xl flex justify-between items-center font-medium '>
                        <span>Filters</span>
                        <IconButton onClick={()=>setOpen(false)}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <SearchByColumn hooks={{
                        fetchOrders,
                        searchStr,
                        setSearchStr,
                        filterField,
                        setFilterFiled,
                        selectWidth: '100px'
                    }} />

                    <div className='w-full my-2'>
                        <h6 className='mb-1 text-gray-600'>Filter By Days</h6>
                        <div className='border rounded-md'>
                            <DaysSelect
                                hooks={{ day, handleDays }}
                                id='select-last-days-filter'
                            />
                        </div>
                    </div>

                    <div id='filter-startdate' className='w-full my-2'>
                        <h6 className='mb-1 text-gray-600'>Start Date</h6>
                        <DatePicker
                            className='w-full h-10 rounded-md px-2 border' selected={apiPayload?.start_date || ''}
                            name='start_date' onChange={(date) => handledate('start_date', date)}
                        />
                    </div>

                    <div id='filter-enddate' className='w-full my-2'>
                        <h6 className='mb-1 text-gray-600'>End Date</h6>
                        <DatePicker
                            className='w-full h-10 rounded-md px-2 border' selected={apiPayload?.end_date || ''}
                            name='end_date' onChange={(date) => handledate('end_date', date)}
                        />
                    </div>
                    <div className='  w-full flex justify-end mt-3 space-x-3' >
                        <button className='border-1 border-gray-500 text-gray-500 px-3 py-1 rounded-md' onClick={handleClose}>Clear</button>
                        <Button className='bg-gradient-to-tr  from-gray-700 to-gray-400 text-white py-1 px-2 font-[300] rounded-md' onClick={handleClose} autoFocus>
                            Apply
                        </Button>
                    </div>
                </div>
            </Drawer>
        </React.Fragment>
    );
}
