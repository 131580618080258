
import * as React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { Badge, IconButton } from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export default function ReOrder() {
  const navigate = useNavigate()
  const carts = useSelector(state => state?.cartReducer?.carts)

  const handleReorder = ()=>{
    navigate('/app/create-order')
    
  }

  return (
    <Tooltip placement='bottom' arrow title='Re-Order'>
      <Badge badgeContent={carts?.ids?.length || 0} color='error'>
        <IconButton onClick={handleReorder} className='bg-white'>
          <ReplayIcon />
        </IconButton>
      </Badge>
    </Tooltip>
  );
}

