import UploadImage from "./uploadImage";

const cols = [
    'File',
    'File Name',
    'File Type',
    'Upload file',
    ''
]
const FileListing = ({ handleFileAdd, data, fileUploadLoader }) => {
    return <>
        <div className="w-full mt-3 min-h-56 overflow-scroll">
            <table className='text-sm w-full relative'>
                <thead>
                    <tr className='text-left capitalize bg-gray-100'>
                        {cols?.map((col) => (
                            <th
                                key={col}
                                title={col}
                                scope='col'
                                className='font-[500] p-2 border text-start'
                            >
                                {col}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {
                        data?.ImageLinks?.map(item => {
                            return (
                                <tr
                                    key={item?.uId}
                                    className='cursor-pointer text-left'
                                >
                                    <td className='border p-1 '>
                                            {item?.file_type === 'Image' && <>
                                                {
                                                    fileUploadLoader === item?.uId ? <div
                                                        className="m-2 inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-gray-300 border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                                                        role="status"
                                                    >
                                                        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                                                            Loading...
                                                        </span>
                                                    </div> : <img
                                                        src={item?.base64path}
                                                        className=' w-32 object-contain'
                                                    />
                                                }

                                            </>}
                                    </td>
                                    <td className='border p-1'>
                                        {item?.file_name}
                                    </td>
                                    <td className='border p-1'>
                                        <select
                                            id={item?.uId}
                                            onChange={(e) => handleFileAdd(e, data?.uId, 'file_type')}
                                            value={item?.file_type}
                                            className="text-sm w-full focus:ring-0 outline-none  placeholder:text-gray-900 rounded-md " >
                                            <option value="" disabled>Select an Option</option>
                                            <option value="File">File</option>
                                            <option value="CAD">CAD</option>
                                            <option value="Image">Image</option>
                                        </select>
                                    </td>
                                    <td className='border p-1 w-72 '>
                                        <UploadImage
                                            rowid={data?.uId}
                                            line_number={item?.line_number}
                                            filerowId={item?.uId}
                                            handleFileAdd={handleFileAdd}
                                        />
                                    </td>
                                    <td className='border p-1 w-28'>
                                        <div className="  flex justify-start space-x-2">
                                            <span
                                                onClick={() => handleFileAdd(item, data?.uId, 'remove')}
                                                title='Delete'
                                                role="button"
                                                className='bg-gray-500 p-1 disabled:bg-gray-500/20 rounded-full text-white'
                                            >
                                                <svg
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    viewBox='0 0 24 24'
                                                    fill='currentColor'
                                                    className='w-4 h-4'
                                                >
                                                    <path
                                                        fillRule='evenodd'
                                                        d='M3.75 12a.75.75 0 01.75-.75h15a.75.75 0 010 1.5h-15a.75.75 0 01-.75-.75z'
                                                        clipRule='evenodd'
                                                    />
                                                </svg>
                                            </span>
                                            <span
                                                title='Add'
                                                role="button"
                                                onClick={() => handleFileAdd(undefined, data?.uId, 'Add')}
                                                className='bg-gray-500 p-1 disabled:bg-gray-500/20 rounded-full text-white'
                                            >
                                                <svg
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    viewBox='0 0 24 24'
                                                    fill='currentColor'
                                                    className='w-4 h-4'
                                                >
                                                    <path
                                                        fillRule='evenodd'
                                                        d='M12 5.25a.75.75 0 01.75.75v5.25H18a.75.75 0 010 1.5h-5.25V18a.75.75 0 01-1.5 0v-5.25H6a.75.75 0 010-1.5h5.25V6a.75.75 0 01.75-.75z'
                                                        clipRule='evenodd'
                                                    />
                                                </svg>
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })
                    }

                </tbody>
            </table>
        </div>
    </>
}

export default FileListing;