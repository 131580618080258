import React, { useState } from 'react';
import { useDispatch } from 'react-redux'
import loginBg from '../../Assets/Images/login-bg.jpg'
import { OTP_GENERATOR, OTP_VELIDATION } from 'src/Services/Redux/Actions/user';
import { useNavigate } from 'react-router-dom';
import { AlertError } from 'src/Services';

function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

export default function LoginLayout() {
    const navigate = useNavigate()
    const [email, setEmail] = useState('');
    const [OTP, setOTP] = useState('');
    const dispatch = useDispatch()
    const [showOTPField, setShowOTPField] = useState(false);
    const [loading, setLoading] = useState(false)

    const emailAPI = async () => {
        setLoading(true)
        let res = await dispatch(OTP_GENERATOR({ email }))
        if (res?.success) {
            setShowOTPField(true)
        }
        setLoading(false)
    };

    const OtpAPI = async () => {
        if (OTP?.length !== 4) {
            AlertError('Invalid OTP provided')
            return
        }
        let res = await dispatch(OTP_VELIDATION({ otp: OTP, email }))
        if (res?.success) {
            // navigate('/app/order')
            navigate('/app/dashboard')
        }
    }

    const handleLogin = (e) => {
        e.preventDefault();
        if (!isValidEmail(email)) {
            AlertError('Please enter a valid email address')
            return
        }
        emailAPI();
    };

    const handleOTP = (e) => {
        // setVerifyOtp(true);
        e.preventDefault();
        OtpAPI();
    }

    return (
        <div
            className='bg-cover bg-center h-screen flex justify-center items-center'
            style={{ backgroundImage: `url(${loginBg})` }}>
            <form className=' h-96 max-w-[300px] min-w-[300px] bg-gray-900/50 backdrop-blur-[2px] border border-white shadow-md rounded-md p-3 justify-center'>
                <div className='flex flex-col items-center '>
                    <h2 className=' my-3 text-3xl text-white '>AJAFFE</h2>
                    <h3 className='text-xl text-gray-100 mb-5'>Login</h3>

                    <div className='w-full'>
                        <label htmlFor="username" className=' my-2 text-sm text-white'>Email</label>
                        <br />
                        <input
                            className={`bg-gray-900/50 placeholder:text-white/65 rounded-md p-2 h-10 text-white text-sm w-full  focus:outline-none focus:ring-0 focus:border-white`}
                            type="email" placeholder='Your verified email' id="username" value={email}
                            onChange={(e) => setEmail(e.target.value)} />
                    </div>

                    {showOTPField ? (
                        <div className='w-full my-2'>
                            <div className='flex justify-between items-center'>
                                <label htmlFor="otp" className='text-white text-sm'>OTP</label>
                                <button className='text-white text-sm' onClick={handleLogin}>
                                    Resend OTP
                                </button>
                            </div>
                            <input className=' bg-gray-900/50  rounded-md p-2 h-10 text-white text-xs w-full placeholder:text-white/50 focus:outline-none focus:ring-0 focus:border-white'
                                type="number" placeholder="OTP" id="OTP" value={OTP}
                                onChange={(e) => setOTP(e.target.value)} />
                            <button
                                onClick={handleOTP}
                                disabled={OTP?.length < 4}
                                className="group relative my-3 h-10 w-full overflow-hidden rounded-lg bg-white  text-lg shadow">
                                <div className=" cursor-pointer absolute inset-0 w-0 bg-gray-200 transition-all duration-[250ms] ease-out group-hover:w-full"></div>
                                <span className="relative  group-hover:text-gray-700">{loading ? 'Loading...' : 'Submit OTP'}</span>
                            </button>
                        </div>
                    ) : (
                        <button
                            onClick={handleLogin}
                            className="group relative my-3 h-10 w-full overflow-hidden rounded-lg bg-white  text-lg shadow">
                            <div className=" cursor-pointer absolute inset-0 w-0 bg-gray-200 transition-all duration-[250ms] ease-out group-hover:w-full"></div>
                            <span className="relative  group-hover:text-gray-700">{loading ? 'Loading...' : 'Login'}</span>
                        </button>
                    )}
                </div>
            </form>
        </div>
    );
}