
import React, { useEffect, useMemo, useState } from 'react';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ReactPaginationStyle from 'src/Components/ReactPagination';
import SelectRows from 'src/Components/SelectRows';
import CardLoader from 'src/Components/CardLoader';
import { GET_ATTCHMENT_DETAILS } from 'src/Services/Redux/Actions/orders';
import { useDispatch, useSelector } from 'react-redux';
import { ACTION_HANLDE_CART_UPDATE } from 'src/Services/Constant/types';
import { AlertInfo } from 'src/Services';
import moment from 'moment'

export default function OrdersContent({ hooks }) {
    let {
        data, totalRecords, selectedRowDetails, setSelectedRowDetails, setImageData, setImage_UniqueID,
        page, handleRows, rows, loader,
        handlePagination
    } = hooks;
    const dispatch = useDispatch();
    const carts = useSelector(state => state?.cartReducer?.carts)
    const [rowSelection, setRowSelection] = useState({});

    const columns = useMemo(
        () => [
            {
                accessorKey: 'ordercreationdatetime',
                header: 'Date',
                filterVariant: 'range',
                Cell: ({ cell }) => {
                    return <span>{moment(new Date(cell?.getValue())).format('MM-DD-YYYY')}</span>;
                  },
            },
            {
                accessorKey: 'salesordernumber',
                header: 'Order',
                filterVariant: 'text',
            },
            {
                accessorKey: 'itemnumber',
                header: 'Style',
                filterVariant: 'text',
            },
            {
                accessorKey: 'taggedcaratwt',
                header: 'Version',
                filterVariant: 'text',
            },
            {
                accessorKey: 'metaldesc',
                header: 'Metal',
                filterVariant: 'text',
            },
            {
                accessorKey: 'productsizeid',
                header: 'Ring Size',
                filterVariant: 'text',
            },
            {
                accessorKey: 'CUSTOMERREQUISITIONNUMBER',
                header: 'Customer PO #',
                filterVariant: 'text',
            }
        ],
        [],
    );

    const runSelectdRowLogic = ()=>{
        const debounceTimer = setTimeout(() => {
            let selectedAre = {}
            for (let id of carts?.ids) {
                selectedAre[id] = true
            }
            setRowSelection(selectedAre)
        }, 900)

        return () => {
            clearTimeout(debounceTimer)
        }
    }

    useEffect(() => {
        runSelectdRowLogic()
    }, [])

    const getSelectedRowDetail = (row) => {
        let payload = {
            INVENTORYLOTID: row?.inventorylotid
        }
        dispatch(GET_ATTCHMENT_DETAILS(payload))
        setSelectedRowDetails(row)
        setImageData(row?.Image_URL)
        setImage_UniqueID(row?.Image_UniqueID)
    }

    const table = useMaterialReactTable({
        columns,
        data,
        initialState: { density: 'compact' },
        enableStickyHeader: true,
        enableFullScreenToggle: true,
        renderBottomToolbar: false,
        enableRowSelection: true,
        getRowId: (row) => row?.inventorylotid,
        state: { rowSelection },
        rowStyle: { backgroundColor: 'red' }, // Set rowStyle here

        muiTableContainerProps: { sx: { maxHeight: '70vh' }, sm: { Height: '80vh' } },

        muiTableBodyRowProps: ({ row }) => ({
            //add onClick to row to select upon clicking anywhere in the row
            onClick: () => getSelectedRowDetail(row?.original), // Corrected onClick handler
            sx: {cursor: 'pointer', backgroundColor: row?.original?.inventorylotid === selectedRowDetails?.inventorylotid ? '#e5e7eb' : ''
            },
            // className:'bg-red-200',
        }),

        renderTopToolbarCustomActions: () => (
            <div className='flex justify-start items-center space-x-4'>
                <ReactPaginationStyle
                    total={totalRecords}
                    currentPage={page}
                    handlePagination={(value) => {
                        handlePagination(value)
                        runSelectdRowLogic()
                        // clear select on page change
                    }}
                    limit={rows}
                />
                <SelectRows rows={rows} setRows={handleRows} />
            </div>
        ),

        onRowSelectionChange: setRowSelection,

    });

    useEffect(() => {
        let checkedItems = Object.keys(rowSelection)
        if (checkedItems?.length) {
            let allCartIds = carts?.ids
            let cartlist = carts?.data

            for (let rowKey of checkedItems) {
                if (!allCartIds?.includes(rowKey)) {
                    let newItem = data?.find(item => item.inventorylotid === rowKey)
                    allCartIds.push(rowKey)
                    cartlist.push({ ...newItem, cartfrom: 'ORDER' })
                } else {
                    // AlertInfo('Item is already in cart')
                }

            }
            dispatch({
                type: ACTION_HANLDE_CART_UPDATE, data: {
                    data: cartlist,
                    ids: allCartIds
                }
            })
        }
    }, [rowSelection])


    return (
        <>
            {
                loader ? <CardLoader /> : <LocalizationProvider>
                    <MaterialReactTable
                        table={table} />
                </LocalizationProvider>
            }
        </>

    );
}
