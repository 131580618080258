
import React, { useEffect, useMemo, useState } from 'react';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ReactPaginationStyle from 'src/Components/ReactPagination';
import SelectRows from 'src/Components/SelectRows';
import CardLoader from 'src/Components/CardLoader';
import { useDispatch, useSelector } from 'react-redux';
import { AlertInfo } from 'src/Services';
import { ACTION_HANLDE_CART_UPDATE } from 'src/Services/Constant/types';
import moment from 'moment'
import { GET_WEBORDER_DETAILS } from 'src/Services/Redux/Actions/webOrders'

export default function WebOrdersTable({ hooks }) {
    let {
        data, totalRecords, selectedRowDetails, setSelectedRowDetails,
        page, handleRows, rows, loader,
        handlePagination
    } = hooks;
    // console.log("Tot rec", data)
    const dispatch = useDispatch();
    const carts = useSelector(state => state?.cartReducer?.carts)
    const [rowSelection, setRowSelection] = useState({});

    const columns = useMemo(
        () => [
            {
                accessorKey: 'WebOrderID',
                header: 'WebOrder ID',
                filterVariant: 'text',
            },
            {
                accessorKey: 'CreatedAt',
                header: 'Date',
                filterVariant: 'range',
                Cell: ({ cell }) => {
                    return <span>{moment(new Date(cell?.getValue())).format('MM-DD-YYYY')}</span>;
                  },
            },
            {
                accessorKey: 'Style',
                header: 'Style',
                filterVariant: 'text',
            },
            {
                accessorKey: 'Version',
                header: 'Version',
                filterVariant: 'text',
            },
            {
                accessorKey: 'Metal',
                header: 'Metal',
                filterVariant: 'text',
            },
            {
                accessorKey: 'RingSize',
                header: 'Ring Size',
                filterVariant: 'text',
            },
            {
                accessorKey: 'Quality',
                header: 'Quality',
                filterVariant: 'text',
            },
        ],
        [],
    );

    const runSelectdRowLogic = ()=>{
        const debounceTimer = setTimeout(() => {
            let selectedAre = {}
            for (let id of carts?.ids) {
                selectedAre[id] = true
            }
            setRowSelection(selectedAre)
        }, 900)

        return () => {
            clearTimeout(debounceTimer)
        }
    }
    
    useEffect(() => {
        runSelectdRowLogic()
    }, [])
    
    const getSelectedRowDetail = (row) => {
        let payload = {
            WebOrderID : row?.WebOrderID,
            id : row?.id
        }
        dispatch(GET_WEBORDER_DETAILS(payload))
        // {row?.Note && <Notes notes={row?.Note} />}
        setSelectedRowDetails(row)
    }

    const uId = (row)=>{
        return `${row?.WebOrderID}-${row?.line_number}`
    }  
      
    const table = useMaterialReactTable({
        columns,
        data,
        initialState: { density: 'compact' },
        enableStickyHeader: true,
        enableFullScreenToggle: true,
        renderBottomToolbar: false,
        enableRowSelection: true,
        getRowId: (row) => uId(row),
        state: { rowSelection },
        muiTableContainerProps: { sx: { maxHeight: '70vh' }, sm: { Height: '80vh' } },

        muiTableBodyRowProps: ({ row }) => ({
            onClick: () => getSelectedRowDetail(row?.original),
            sx: { cursor: 'pointer', backgroundColor: uId(row?.original) === uId(selectedRowDetails) ? '#e5e7eb' : '' }
        }),

        renderTopToolbarCustomActions: () => (
            <div className='flex justify-start items-center space-x-4'>
                <ReactPaginationStyle
                    total={totalRecords}
                    currentPage={page}
                    handlePagination={(value) => {
                        handlePagination(value)
                        runSelectdRowLogic()
                    }}
                    limit={rows}
                />
                <SelectRows rows={rows} setRows={handleRows} />
            </div>
        ),

        onRowSelectionChange: setRowSelection,

    });


    useEffect(() => {
        let checkedItems = Object.keys(rowSelection)
        if(checkedItems?.length){
            let allCartIds = carts?.ids
            let cartlist = carts?.data
    
            for (let rowKey of checkedItems) {
                if(!allCartIds?.includes(rowKey)){
                    let newItem = data?.find(item => uId(item) === rowKey)
                    allCartIds.push(rowKey)
                    cartlist.push({...newItem, cartfrom:'WEB_ORDER'})
                }else{
                    AlertInfo('Item is already in cart')
                }
              
            }
            dispatch({
                type: ACTION_HANLDE_CART_UPDATE, data: {
                    data: cartlist,
                    ids: allCartIds
                }
            })
        }
    }, [rowSelection])

    return (
        <>
            {
                loader ? <CardLoader /> : <LocalizationProvider>
                    <MaterialReactTable
                        getRowId={(row) => console.log(row)}
                        table={table} />
                </LocalizationProvider>
            }
        </>

    );
}
