import Sidebar from "src/Components/Sidebar";
import ProtectedRoute from "./protectedRoutes";

const RouteLayout = () => {
    return (
        <main className="h-[100vh] bg-gray-200 flex justify-start w-full overflow-hidden">
            <div className="max-w-[240px]">
                <Sidebar />
            </div>
            <div className="flex-grow flex flex-col overflow-y-scroll text-[#4D4D4D] p-2 w-full bg-lightGray">
                <ProtectedRoute />
            </div>
        </main>
    )
}

export default RouteLayout