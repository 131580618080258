import orderBoxImage from 'src/Assets/Images/order-box.png'
import ImageWithFallbackAndLoader from 'src/Components/ImageLoader';

const BasicDetails = ({ rowData }) => {
    return (
        <div className="shadow-md bg-white rounded-md p-2 mt-2">
            <div className="grid grid-cols-1 gap-4 mt-4 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-1">
                <div className='w-full'>
                    <ImageWithFallbackAndLoader
                        className=' w-full h-52 object-contain '
                        fallbackSrc={orderBoxImage}
                        // src={rowData?.Image_Links}
                        src="https://google.com"
                        alt={'image link'}
                    />
                </div>
                <div>
                    <div>
                        <h6 className=" font-[700] text-gray-600">Quality:</h6>
                        <p> {rowData?.Quality}</p>
                    </div>
                    <div>
                        <h6 className=" font-[700] text-gray-600 mt-10">Center Quality:</h6>
                        <p> {rowData?.CenterQuality}</p>
                    </div>
                    <div>
                        <h6 className=" font-[700] text-gray-600 mt-10">Center Size:</h6>
                        <p> {rowData?.CenterSize}</p>
                    </div>
                </div>
                <div>
                    <div>
                        <h6 className=" font-[700] text-gray-600">Customer Center.:</h6>
                        <p> {rowData?.CustomerCenter}</p>
                    </div>
                    <div>
                        <h6 className=" font-[700] text-gray-600 mt-10">Center:</h6>
                        <p> {rowData?.Center}</p>
                    </div>
                    <div>
                        <h6 className=" font-[700] text-gray-600 mt-10">Two Tone:</h6>
                        <p> {rowData?.TwoTone}</p>
                    </div>
                </div>
                <div>
                    <div>
                        <h6 className=" font-[700] text-gray-600">Center Shape:</h6>
                        <p> {rowData?.CenterShape}</p>
                    </div>
                    <div>
                        <h6 className=" font-[700] text-gray-600 mt-10">Center Stone Width:</h6>
                        <p> {rowData?.CenterStoneWidth}</p>
                    </div>
                    <div>
                        <h6 className=" font-[700] text-gray-600 mt-10">Center Stone Length:</h6>
                        <p> {rowData?.CenterStoneLength}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BasicDetails;