import { ACTION_GET_WEBORDERS } from "src/Services/Constant/types"
import { ACTION_GET_WEBORDER_DETAILS } from "src/Services/Constant/types"

// **  Initial State
const initialState = {
    webOrders: { list: [], pagination: {} },
    webORderDetails : {list:[]}
}

const WebOrdersReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_GET_WEBORDERS:
            return {
                ...state,
                webOrders: action.data
            }
        case  ACTION_GET_WEBORDER_DETAILS:
            return {
                ...state,
                webORderDetails: action.data
            }
        default:
            return state
    }
}

export default WebOrdersReducer