// import { useEffect } from 'react'
import { ToastContainer } from 'react-toastify'
import OrderLayout from 'src/Layouts/orders/list'

const OrdersPage = () => {

    return (
        <div className='w-full'>
            <OrderLayout />
            <ToastContainer
                position="bottom-center"
                newestOnTop limit={1} />
        </div>
    )
}

export default OrdersPage