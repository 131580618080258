export const defaultItemRow = () => {
    return {
        listOfVersions: [],
        line_number: 1,
        Style: "",
        CenterShape: "",
        CenterStoneWidth: 0,
        CenterStoneLength: "",
        TwoTone: "",
        ImageLinks: [
            {
                line_number: 1,
                file_path: "",
                file_name: "",
                file_type: "",
                uId: Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36),
            },
        ],
        Version: "",
        Metal: "",
        RingSize: "",
        Quality: "",
        CenterSize: "",
        CenterQuality: "",
        CustomerCenter: "",
        Center: "",
        Quantity: "",
        Note: "",
        uId: Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36),
    }
}