import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { GET_TOTAL_RETURNS } from 'src/Services/Redux/Actions/totalReturns'
import InvoicePreview from './invoices';
import SalesPreview from './sales';
import moment from 'moment';
import { GET_ORDERS } from 'src/Services/Redux/Actions/orders';
import { GET_INVOICES } from 'src/Services/Redux/Actions/invoices';

const DashboardCharts = () => {
    const dispatch = useDispatch()
    let userinfo = JSON.parse(localStorage.getItem('userinfo'))
    const totalReturns = useSelector(state => state?.totalReturnsReducer?.totalReturns)
    const apiPayload = {
        start_date: moment().subtract(1, 'M').format('MM-DD-YYYY'),
        end_date: moment(new Date()).format('MM-DD-YYYY'),
        pageno: 1,
        limit: 10,
        searchStr: "",
        searchColumn: "",
        totalRecords: 0,
        email: userinfo?.email,
    }

    const fetchReturns = () => {
        const formData = {
            email: userinfo?.email
        };
        dispatch(GET_TOTAL_RETURNS(formData))
        dispatch(GET_ORDERS(apiPayload))
        dispatch(GET_INVOICES(apiPayload))
    }

    useEffect(() => {
        fetchReturns();
    }, [])

    return (
        <>
            <div className="grid grid-cols-1 gap-4 mt-4 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-1">
                <div className="flex-1 p-2 py-3 justify-center bg-white shadow rounded">
                    <p className="text-gray-900 font-bold">Total Sales</p>
                    <p className="pt-3 text-4xl font-bold text-green-500">{totalReturns?.data[0]?.Total_Sales || 'N/A'}</p>
                </div>
                <div className="flex-1 p-2 py-3 justify-center bg-white shadow rounded max-h-300px">
                    <p className="text-gray-900 font-bold">Total Returns</p>
                    <p className="pt-3 text-4xl font-bold text-red-500">{(totalReturns?.data[0]?.Total_Returns * -1) || 'N/A'}</p>
                </div>
                <div className="flex-1 p-2 py-3 justify-center bg-white shadow rounded max-h-300px">
                    <p className="text-gray-900 font-bold">Net Sales</p>
                    <p className={`pt-3 text-4xl font-bold ${totalReturns?.data[0]?.Net_Sales > 0 ? "text-green-500" : "text-red-500"}`}>{totalReturns?.data[0]?.Net_Sales}</p>
                </div>
            </div>

            <section className="h-full grid grid-cols-1 gap-4 my-4 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1">
                <SalesPreview />
                <InvoicePreview />
            </section>

        </>
    );
};

export default DashboardCharts;