import { ACTION_LOGIN_USER } from "src/Services/Constant/types"

// **  Initial State
const initialState = {
    logedInUser: null,
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_LOGIN_USER:
            return {
                ...state,
                logedInUser: action.data
            }
        default:
            return state
    }
}

export default userReducer