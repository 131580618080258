// ** Redux Imports
import { combineReducers } from 'redux'
import ordersReducer from './orders'
import userReducer from './user'
import WebOrdersReducer from './webOrders'
import cartReducer from './cart'
import ArchiveOrdersReducer from './archiveOrders'
import InvoicesReducer from './invoices'
import totalReturnsReducer from './totalReturns'

const rootReducer = combineReducers({
    ordersReducer,
    userReducer,
    WebOrdersReducer,
    cartReducer,
    ArchiveOrdersReducer,
    InvoicesReducer,
    totalReturnsReducer
})

export default rootReducer
