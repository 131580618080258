import { postMethodApiCall } from "src/Services/API"
import { ACTION_GET_TOTAL_RETURNS } from "src/Services/Constant/types"

const baseUrl = process.env.REACT_APP_BASE_URL

const getHeadersWithoutAuth = () => {
    return {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('token')}`
    }
}

export const GET_TOTAL_RETURNS = data => {
    return async dispatch => {
        try {
            let payload = {
                ...data
            }
            const url = `${baseUrl}/odata/get/dashboardData`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            if (res?.status === 200 || res?.status === 201) {
                dispatch({ type: ACTION_GET_TOTAL_RETURNS, data: res?.data || null })
            } else {
                return { success: false, message: res?.data?.message }
            }
        } catch (error) {
            console.log((error?.response?.data).replace(/\\/g, ""))
        }
    }
}