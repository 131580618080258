import React from 'react';
import { createRoot } from 'react-dom/client'; // Import createRoot
import { Provider } from 'react-redux'
import 'react-toastify/dist/ReactToastify.css';
import store from './Services/Redux/Store';
import './index.css';
import AllRoutes from './Routes';

const container = document.getElementById('root');

const root = createRoot(container);

root.render(
  <Provider store={store}>
      <AllRoutes />
  </Provider>
);