

const CardLoader = () => {
    return <div className='flex items-center justify-center w-full min-h-[70vh] bg-white'>
        <div
            className="inline-block h-9 w-9 animate-spin rounded-full border-4 border-solid border-gray-600 border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
            role="status"
        >
            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                Loading...
            </span>
        </div>
    </div>
}

export default CardLoader