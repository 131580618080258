import { useSelector } from 'react-redux'
import DownlaodBotton from 'src/Layouts/downloadButton'

const cols = ['File Name', 'File Type', 'Attachment Description', 'Attachment File Name', 'Attachment', 'Download']

const FilesListing = () => {
    const getOrderAttachment = useSelector(state => state?.ordersReducer?.getOrderAttachment)

    return (
        <div className="flex flex-col">
            <div className="-m-1.5 overflow-x-auto">
                <div className="p-1.5 min-w-full inline-block align-middle">
                    <div className="overflow-hidden">
                        <table className="min-w-full divide-y divide-gray-200 ">
                            <thead>
                                <tr>
                                    {cols?.map((col, i) => (
                                        < th
                                            key={i}
                                            scope="col"
                                            className="px-6 py-3 text-start text-sm font-[600] text-gray-700 capitalize"
                                        >
                                            {col}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 ">
                                {
                                    getOrderAttachment?.map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 ">
                                                    {item?.FILENAME}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 ">
                                                    {item?.FILETYPE}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 ">
                                                    {item?.ATTACHMENTDESCRIPTION}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 ">
                                                    {item?.ATTACHMENTFILENAME}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 ">
                                                    {item?.ATTACHMENT}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 ">
                                                    {item?.NOTES && <DownlaodBotton
                                                        filename={item?.FILENAME}
                                                        fileExtention={item?.fileExtention}
                                                        link={item?.NOTES} />}
                                                </td>

                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div >

    )
}

export default FilesListing;