import { useSelector } from 'react-redux'

const Notes = () => {
    const getWebOrderAttachment = useSelector(state => state?.WebOrdersReducer?.webORderDetails)
    return (
        <>
            <div>
                <div className='ml-10'>
                    <h6 className=" font-[700] text-gray-600 py-2">Note:</h6>
                    <p>{getWebOrderAttachment?.list?.[0]?.Note}</p>
                </div>
            </div>
        </>
    )
}

export default Notes;