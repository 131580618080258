import { useEffect } from 'react';
import orderBoxImage from 'src/Assets/Images/order-box.png'
import ImageWithFallbackAndLoader from 'src/Components/ImageLoader';

const BasicDetails = ({ rowData, image_uniqueID }) => {
    console.log("Link", image_uniqueID)
    return (
        <div className="shadow-md bg-white rounded-md p-2 mt-2">
            <div className="grid grid-cols-1 gap-4 mt-4 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-1">
                <div className='w-full'>
                    <ImageWithFallbackAndLoader
                        className=' w-full h-52 object-contain '
                        fallbackSrc={orderBoxImage}
                        src={`https://lh3.googleusercontent.com/d/${image_uniqueID}`}
                        alt={'image link'}
                    />
                </div>
                <div>
                    <div>
                        <h6 className=" font-[700] text-gray-600">Contact Person ID:</h6>
                        <p> {rowData?.contactpersonid}</p>
                    </div>
                    <div>
                        <h6 className=" font-[700] text-gray-600 mt-14">Customer Number :</h6>
                        <p> {rowData?.orderingcustomeraccountnumber}</p>
                    </div>
                    <div>
                        <h6 className=" font-[700] text-gray-600 mt-14">Invoice Customer Account No.:</h6>
                        <p> {rowData?.invoicecustomeraccountnumber}</p>
                    </div>
                </div>
                <div>
                    <div>
                        <h6 className=" font-[700] text-gray-600">Order Reference:</h6>
                        <p> {rowData?.customersorderreference}</p>
                    </div>
                    <div>
                        <h6 className=" font-[700] text-gray-600 mt-14">Line Status:</h6>
                        <p> {rowData?.salesorderlinestatus}</p>
                    </div>
                    <div>
                        <h6 className=" font-[700] text-gray-600 mt-14">Config Name:</h6>
                        <p> {rowData?.configname}</p>
                    </div>
                </div>
                <div>
                    <div>
                        <h6 className=" font-[700] text-gray-600">Center Stone Shape:</h6>
                        <p> {rowData?.centerstoneshape}</p>
                    </div>
                    <div>
                        <h6 className=" font-[700] text-gray-600 mt-14">Sales Price:</h6>
                        <p> {rowData?.SALESPRICE}</p>
                    </div>
                    <div>
                        <h6 className=" font-[700] text-gray-600 mt-14">Line Amount:</h6>
                        <p> {rowData?.LINEAMOUNT}</p>
                    </div>
                </div>
                <div>
                    <div>
                        <h6 className=" font-[700] text-gray-600">Product Config ID:</h6>
                        <p> {rowData?.PRODUCTCONFIGURATIONID}</p>
                    </div>
                    <div>
                        <h6 className=" font-[700] text-gray-600 mt-10">Delivery City:</h6>
                        <p> {rowData?.DELIVERYADDRESSCITY}</p>
                    </div>
                    <div>
                        <h6 className=" font-[700] text-gray-600 mt-10">Delivery Street:</h6>
                        <p> {rowData?.DELIVERYADDRESSSTREET}</p>
                    </div>
                </div>
                <div>
                    <div>
                        <h6 className=" font-[700] text-gray-600">Delivery Zip Code:</h6>
                        <p> {rowData?.DELIVERYADDRESSZIPCODE}</p>
                    </div>
                    <div>
                        <h6 className=" font-[700] text-gray-600 mt-10">Delivery Country/Region ID:</h6>
                        <p> {rowData?.DELIVERYADDRESSCOUNTRYREGIONID}</p>
                    </div>
                    <div>
                        <h6 className=" font-[700] text-gray-600 mt-10">Delivery State ID:</h6>
                        <p> {rowData?.DELIVERYADDRESSSTATEID}</p>
                    </div>
                </div>
                <div>
                    <div>
                        <h6 className=" font-[700] text-gray-600">Center Stone Quality:</h6>
                        <p> {rowData?.CENTERSTONEQUALITY}</p>
                    </div>
                    <div>
                        <h6 className=" font-[700] text-gray-600 mt-10">Center Stone Weight:</h6>
                        <p> {rowData?.CENTERSTONEWEIGHT}</p>
                    </div>
                    <div>
                        <h6 className=" font-[700] text-gray-600 mt-10">Customer Requisition No.:</h6>
                        <p> {rowData?.customerrequisitionnumber}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BasicDetails;