
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import ReactPaginate from "react-paginate";

const ReactPaginationStyle = ({ total, currentPage, handlePagination, limit }) => {
    const count = Number(Math.ceil(total / limit));

    return (
        <ReactPaginate
            previousLabel={<ArrowBack fontSize="small" />}
            nextLabel={<ArrowForward fontSize="small" />}
            pageCount={count || 1}
            breakLabel='...'
            pageRangeDisplayed={5}
            // previousClassName='w-6 h-6 bg-gray-100 border'
            activeLinkClassName='bg-secondary text-white px-2 h-6 rounded-md   flex justify-center items-center'
            pageClassName='flex justify-center text-secondary items-center rounded-md border h-6 min-w-6'
            marginPagesDisplayed={1}
            forcePage={currentPage !== 0 ? currentPage - 1 : 0}
            onPageChange={(page) => handlePagination(page?.selected+1)}
            // pageClassName={'page-item'}
            // nextClassName={'page-item next'}
            // pageLinkClassName={'page-link'}
            containerClassName="pagination react-paginate rounded-md text-sm  gap-2 flex justify-end my-2 rounded-md"
        />
    )
}

export default ReactPaginationStyle;