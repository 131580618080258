const RowDetails = ({ data, handleChange }) => {
    return <div className="text-sm mt-4 w-full grid grid-cols-1 gap-2 lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1">
        <div>
            <label>Center Shape</label>
            <br />
            <input
                id={data?.uId}
                value={data?.CenterShape || ''}
                onChange={handleChange}
                name="CenterShape"
                className="border w-full focus:ring-0 outline-none  placeholder:text-gray-900 p-1.5 px-2 rounded-md " />
        </div>
        <div>
            <label>Center Millimeter</label>
            <br />
            <input
                id={data?.uId}
                value={data?.CenterStoneLength || ''}
                onChange={handleChange}
                name="CenterStoneLength"
                className="border w-full focus:ring-0 outline-none  placeholder:text-gray-900 p-1.5 px-2 rounded-md " />
        </div>
        <div>
            <label>Center Stone Width</label>
            <br />
            <input
                id={data?.uId}
                value={data?.CenterStoneWidth }
                onChange={handleChange}
                name="CenterStoneWidth"
                type='text' className="border w-full focus:ring-0 outline-none  placeholder:text-gray-900 p-1.5 px-2 rounded-md " />
        </div>
        <div>
            <label>Two Tone</label>
            <br />
            <select
                id={data?.uId}
                value={data?.TwoTone || ''}
                onChange={handleChange}
                name="TwoTone"
                className="border w-full focus:ring-0 outline-none  placeholder:text-gray-900 p-1.5 px-2 rounded-md">
                <option value="" disabled>Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
            </select>
        </div>
    </div>
}

export default RowDetails;