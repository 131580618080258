import ToastContent from "src/Components/ToastContent";
import { toast, Zoom } from 'react-toastify';


const successicon = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
</svg>

let erroricon = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
</svg>


export const AlertSuccess = (text, title = 'Success !', time = 2000) => {
    return toast.success(
        <ToastContent type='success'
            icon={successicon}
            text={<span className="fw-lighter">{text}</span>}
            title={<span className="fw-lighter text-success" >{title}</span>} />,
        { transition: Zoom, hideProgressBar: true, autoClose: time, className: 'text-sm rounded-lg shadow' }
    )
}


export const AlertError = (title = 'Fail :(', text) => {
    return toast.error(
        <ToastContent type='light-danger'
            icon={erroricon}
            title={title}
            text={<span className="fw-light">{typeof text === 'object' ? 'Something is wrong Please  try on more time ' : text} </span>} />,
        { transition: Zoom, hideProgressBar: true, autoClose: 2000, className: 'text-sm rounded-lg shadow' }
    )
}

export const AlertInfo = (title = 'Fail :(', text) => {
    return toast.info(
        <ToastContent 
            title={title}
            text={<span className="fw-light">{typeof text === 'object' ? 'Something is wrong Please  try on more time ' : text} </span>} />,
        { transition: Zoom, hideProgressBar: true, autoClose: 2000, className: 'text-sm rounded-lg shadow' }
    )
}



