import { ToastContainer } from "react-toastify"
import ArchiveOrderLayout from "src/Layouts/archived"

const ArchiveOrdersPage = () => {

    return (
        <>
            <ArchiveOrderLayout />
            <ToastContainer
            position="bottom-center"
            newestOnTop limit={1} />
        </>
    )
}

export default ArchiveOrdersPage