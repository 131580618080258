import { ACTION_GET_ARCHIVEORDERS } from "src/Services/Constant/types"

// **  Initial State
const initialState = {
    archiveOrders: { list: [], pagination: {} }
}

const ArchiveOrdersReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_GET_ARCHIVEORDERS:
            return {
                ...state,
                archiveOrders: action.data
            }
        default:
            return state
    }
}

export default ArchiveOrdersReducer