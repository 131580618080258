import { ACTION_GET_INVOICES } from "src/Services/Constant/types"

// **  Initial State
const initialState = {
    invoices: { list: [], pagination: {} }
}

const InvoicesReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_GET_INVOICES:
            return {
                ...state,
                invoices : action.data
            }
        default:
            return state
    }
}

export default InvoicesReducer