import { ACTION_GET_TOTAL_RETURNS } from "src/Services/Constant/types"

// **  Initial State
const initialState = {
    getTotalReturns : {}
}

const totalReturnsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_GET_TOTAL_RETURNS:
            return {
                ...state,
                totalReturns: action.data
            }
        default:
            return state
    }
}

export default totalReturnsReducer