import { ToastContainer } from "react-toastify"
import CreateOrderLayout from "src/Layouts/orders/create"


const CreateOrder = ()=>{
    return (
        <>
            <CreateOrderLayout />
            <ToastContainer
            position="bottom-center"
            newestOnTop limit={1} />
        </>
    )
}

export default CreateOrder