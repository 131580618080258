import { ToastContainer } from "react-toastify"
import WebOrderLayout from "src/Layouts/webOrders"

const WebOrdersPage = () => {

    return (
        <div className='w-full'>
            <WebOrderLayout />
            <ToastContainer
                position="bottom-center"
                newestOnTop limit={1} />
        </div>
    )
}

export default WebOrdersPage