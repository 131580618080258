
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { AlertInfo } from 'src/Services'
import { GET_VERSIONS } from 'src/Services/Redux/Actions/orders'

const cols = ['Version', 'Shape', 'Weight']

const DropdownInput = ({ props: { data, version, handleVersionSelect, rowUID } }) => {
    const dropdownRef = useRef(null)
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        const handleOutsideClick = event => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false)
            }
        }

        document.addEventListener('mousedown', handleOutsideClick)

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick)
        }
    }, [])

    const handleCustomVersion = (rowUID) => {
        handleVersionSelect({TAGGEDCARATWT: undefined}, rowUID)
        setIsOpen(false)
        AlertInfo('Add Your Custom Version in note section')
    }

    return (
        <div ref={dropdownRef} className='relative w-full mt-1'>
            <span role='button' onClick={() => setIsOpen(true)} className='w-full text-left text-sm'>
                {version === undefined ? 'Custom Version' : (version || 'Select... ')}
            </span>

            {(isOpen && data?.length > 0) && (
                <div
                    className=' z-50 p-2 min-w-[290px] transition ease-in duration-100 absolute mt-1 w-full max-h-72 overflow-scroll rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'
                >
                    <table className=' text-sm w-full relative'>
                        <thead>
                            <tr className='text-left capitalize bg-gray-50'>
                                {cols?.map((col) => (
                                    <th
                                        key={col}
                                        title={col}
                                        scope='col'
                                        className='font-[500] p-2 border text-start'
                                    >
                                        {col}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data?.map((item, i) => {
                                    return (
                                        <tr
                                            onClick={() => {
                                                handleVersionSelect(item, rowUID)
                                                setIsOpen(false)
                                            }}
                                            key={i + item?.TAGGEDCARATWT + item?.CENTERSTONESHAPE + item?.CENTERSTONEWEIGHT}
                                            className='cursor-pointer hover:bg-gray-300 text-left'
                                        >
                                            <td className='border p-1'>
                                                {item?.TAGGEDCARATWT}
                                            </td>
                                            <td className='border p-1'>{item?.CENTERSTONESHAPE}</td>
                                            <td className='border p-1'>{item?.CENTERSTONEWEIGHT}</td>
                                        </tr>
                                    )
                                })
                            }

                        </tbody>
                    </table>
                    <button
                        onClick={() => handleCustomVersion(rowUID)}
                        className='border-l border-r border-b w-full p-1'>
                        Custom Version
                    </button>
                </div>
            )}
        </div>
    )
}


const VersionDropdown = (props) => {
    const dispatch = useDispatch()
    let { row, rowUID, items, setItems } = props

    useEffect(() => {
        if (props?.row?.Style?.length >= 3) {
            dispatch(GET_VERSIONS({ Style: row?.Style })).then(versions => {
                if (versions.length > 0) {
                    let newData = items?.map(it => {
                        if (it?.uId === rowUID) {
                            it['listOfVersions'] = versions
                        }
                        return it
                    })
                    setItems([...newData])
                }
            })
        }
    }, [props?.row?.Style])

    return (
        <>
            <DropdownInput props={props} />
        </>
    )
}

export default VersionDropdown