import { useState, useEffect } from "react";
import ItemTable from "./itemtable";
import UserAndShipmentDetails from "./userAndShipmentDetail";
import { defaultItemRow } from "./defaultItemData";
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import { CREATE_NEW_ORDER } from "src/Services/Redux/Actions/orders";
import { useNavigate } from "react-router-dom";
import { AlertSuccess } from "src/Services";
import { GET_CUSTOMER_DETAILS } from "../../../Services/Redux/Actions/shippingDetails";

const OrderNo = Math.floor(Math.random() * 1E16)

const CreateOrderLayout = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    let userinfo = JSON.parse(localStorage.getItem('userinfo'))
    const [loader, setLoader] = useState(false)
    const carts = useSelector(state => state?.cartReducer?.carts)
    const { register, watch, setValue, handleSubmit, formState: { errors } } = useForm();
    const [items, setItems] = useState([defaultItemRow()])

    const handleCancel = () => {
        navigate('/app/order')
        setItems([defaultItemRow()])
    }

    const onSubmit = async (data) => {
        setLoader(true)
        let itemsRows = await items.map(i => {
            let row = { ...i }
            row['ImageLinks'] = row?.ImageLinks?.map(file => {
                let f = { ...file }
                delete f['base64path']
                delete f['file']
                delete f['uId']
                return f
            })
            if (row?.inventorylotid) {
                delete row['inventorylotid']
            }
            delete row['uId']
            delete row['listOfVersions']
            return row
        })
        let payload = {
            "order": {
                ...data,
                WebOrderID: OrderNo,
                "Email": userinfo?.email,
                Rows: itemsRows || []
            },
            "email": userinfo?.email
        }
        console.log(payload)
        let res = await dispatch(CREATE_NEW_ORDER(payload))
        if (res) {
            AlertSuccess('Order created successfully')
            navigate('/app/order')
        }
        setLoader(false)
    };

    useEffect(() => {
        let payload;
        const subscription = watch((value, { name, type }) => {
            if (name === "ShippingAccount") {
                payload = {
                    "ShippingAccount": value["ShippingAccount"]
                }
                call()
            }
        }
        )

        const call = async () => {
            const val = await dispatch(GET_CUSTOMER_DETAILS(payload))
            if (Array.isArray(val)) {
                if (val.length > 0) {
                    setValue("Phone", val[0]["PRIMARYCONTACTPHONE"]);
                    setValue("Zip_Code", val[0]["DELIVERYADDRESSZIPCODE"]);
                    setValue("Address1", val[0]["DELIVERYADDRESSSTREET"]);
                    setValue("State", val[0]["DELIVERYADDRESSSTATE"]);
                    setValue("City", val[0]["DELIVERYADDRESSCITY"]);
                    setValue("EmailEntered", val[0]["PRIMARYCONTACTEMAIL"]);
                }
            }
        }

        return () => subscription.unsubscribe()
    }, [watch, dispatch])

    useEffect(() => {
        if (carts?.data?.length > 0) {
            let afterUpdate = carts?.data?.map(cart => {
                let item = { ...defaultItemRow(), ...cart }
                item['Style'] = cart?.itemnumber
                item['Version'] = cart?.taggedcaratwt
                item['CenterSize'] = cart?.CENTERSTONEWEIGHT
                item['Metal'] = cart?.metaldesc
                item['CenterQuality'] = cart?.CENTERSTONEQUALITY
                item['RingSize'] = cart?.productsizeid
                item['CenterShape'] = cart?.centerstoneshape
                item['RingSize'] = cart?.productsizeid
                return item
            })
            setItems(afterUpdate)
        }
    }, [])

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="w-full h-full my-3">
            <div className="flex justify-between items-center">
                <h2 className='text-gray-800 font-[600] text-lg'>Create Order / <span className="text-sm text-gray-500">Web Order ID : {OrderNo}</span></h2>
                <div className="flex justify-start items-center space-x-2">
                    <button
                        onClick={handleCancel}
                        className="bg-gray-100 border shadow-sm px-3 py-1.5 rounded-md">
                        Cancel
                    </button>
                    <button disabled={loader} className="shadow bg-gradient-to-tr px-3 from-gray-700 to-gray-400 text-white py-1.5 font-[300] rounded-md">
                        {loader ? 'Processing...' : 'Submit'}
                    </button>
                </div>
            </div>
            <UserAndShipmentDetails
                setValue={setValue}
                errors={errors}
                register={register}
            />

            <ItemTable items={items}
                OrderNo={OrderNo}
                setItems={setItems} />
            <br />
            <br />
            <br />
            <br />
        </form>
    )
}

export default CreateOrderLayout;